<template>
	<router-view></router-view>
</template>

<script>
	export default {
		name: "v-im",
	};
</script>
<style lang="scss">
	html {
		font-size: 16px;
		height: 100%;
	}

	body {
		height: 100%;
		margin: 0;
		padding: 0;
	}

	#app {
		// width: 100%;
		// height: 100%;
		// margin: 0 auto;
		width: 1000px;
		height: 500px;
		margin: 30px auto
	}
</style>